import { useFetcher } from '@remix-run/react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { FormProps, TouchedFields, useFormContext, ValidatedForm } from 'remix-validated-form'
import { twMerge } from 'tailwind-merge'
import Button from '../Button'
import FormHiddenInput from './FormHiddenInput'
import { trace } from 'lib/logging'

type SmartFormProps<
  DataType extends {
    [fieldName: string]: any
  },
> = {
  id?: string
  className?: string
  encType?: string
  method?: string
  showDelete?: boolean
  hideButtons?: boolean
  replace?: boolean
  shouldPreventDefault?: boolean
  hiddenInputs?: { name: string; value?: string | string[] }[]
  preventSubmit?: boolean
  addDefaultClass?: boolean
  formClassNames?: string
  onTouchedFields?: (touched: boolean, touchedFields?: TouchedFields) => void
  onCancel?: () => void
  onDelete?: () => void
  onPreventDefault?: (args?: any) => void
  onSubmit?: (data?: DataType) => void
} & FormProps<DataType, any>

export enum SubAction {
  CREATE = `create`,
  EDIT = `edit`,
  DELETE = `delete`,
  ARCHIVE = `archive`,
}
/**
 * Main form component to be used which wraps remix-validated-form's ValidatedForm component.
 * @summary Use this to create a form in a page, passing as children only wrapped inputs such as FormInput, FormSelect, etc.
 * @param {SmartFormProps<DataType>} SmartFormProps - Pass in both props to be passed down to ValidatedForm and props for SmartForm such as children, hiddenInputs, etc.
 * @return {ReturnValueDataTypeHere} Gives you a ValidatedForm with hidden inputs, default form buttons and lets you insert whatever inputs you need.
 */

export function SmartForm<
  DataType extends {
    [fieldName: string]: any
  },
>({
  id = `smartForm`,
  method = `post`,
  className = ``,
  encType = `application/x-www-form-urlencoded`,
  replace = true,
  showDelete = false,
  hideButtons = false,
  shouldPreventDefault = false,
  validator,
  defaultValues,
  hiddenInputs,
  children,
  subaction,
  preventSubmit = false,
  formClassNames,
  addDefaultClass = false,
  onCancel = () => history.back(),
  onTouchedFields: onFieldsChange,
  onDelete = () => {},
  onPreventDefault = () => {},
  onSubmit,
  action,
  ref,
}: SmartFormProps<DataType>) {
  const smartFormFetcher = useFetcher()
  const formContext = useFormContext(id)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const getHiddenInputErrors = () => {
    return Object.entries(formContext.fieldErrors).filter(
      ([name, error]) => error && hiddenInputs?.find((hidden) => hidden.name === name),
    )
  }

  const handleSubmit = (data: DataType, event: React.FormEvent<HTMLFormElement>) => {
    trace(`form`, `handleSubmit`, id, data, preventSubmit)
    if (preventSubmit) {
      setIsSubmitting(true)
      event.preventDefault()
      event.stopPropagation()
    }
    onSubmit?.(data)
  }

  useEffect(() => {
    if (shouldPreventDefault && smartFormFetcher.state === `loading`) {
      onPreventDefault?.(smartFormFetcher.data)
    }
  }, [smartFormFetcher])

  useEffect(() => {
    if (Object.entries(formContext.fieldErrors).length) {
      // eslint-disable-next-line no-console
      console.error(`Form errors`, id, formContext.fieldErrors)
    }
  }, [formContext.fieldErrors])

  useEffect(() => {
    let fieldsChanged = Object.entries(formContext.touchedFields).filter(([k, v]) => {
      let defaultValue = defaultValues?.[k] ?? ``
      return (
        v &&
        ((formContext.getValues().has(k) && defaultValue !== formContext.getValues().get(k)) ||
          (formContext.getValues().has(`${k}[value]`) && defaultValue !== formContext.getValues().get(`${k}[value]`)) ||
          (v && !formContext.getValues().has(k)))
      )
    })
    onFieldsChange?.(fieldsChanged.length > 0, formContext.touchedFields)
  }, [formContext.touchedFields])

  return (
    <>
      <ValidatedForm
        encType={encType}
        replace={replace}
        validator={validator}
        defaultValues={defaultValues}
        subaction={subaction}
        method={method}
        action={action}
        id={id}
        autoComplete="off"
        fetcher={shouldPreventDefault ? smartFormFetcher : undefined}
        onSubmit={handleSubmit}
        className={classNames(formClassNames, { 'flex grow overflow-hidden': addDefaultClass })}
        ref={ref}
      >
        <div className={twMerge(`mt-6 grid max-w-3xl gap-x-4 gap-y-6 sm:grid-cols-6`, className)}>
          {hiddenInputs &&
            hiddenInputs?.map((hiddenInput, i) => (
              <FormHiddenInput key={i} name={hiddenInput.name} value={hiddenInput.value} />
            ))}
          {children}
          {getHiddenInputErrors().map(([k, error], i) => (
            <p key={`${k}-${i}`} className="col-span-6 mt-4 text-red-500">
              {error}
            </p>
          ))}
          {!hideButtons && (
            <div className="sticky bottom-4 z-10 col-span-6 my-4 bg-white">
              <div className="flex justify-end gap-4">
                <Button label="Cancel" mode="white" size="md" onClick={onCancel} />
                {showDelete && <Button label="Delete" size="md" mode="secondary" onClick={() => onDelete()} />}

                <Button type="submit" label="Save" mode="primary" size="md" loading={isSubmitting} />
              </div>
            </div>
          )}
        </div>
      </ValidatedForm>
    </>
  )
}
